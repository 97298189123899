




// CORE
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

@Component
export default class EntityValidate extends Mixins(SystemMixin) {
  private mounted () {
    if (!this.entityFull) {
      this.$router.push({ name: 'role.entity' })
    }
  }
}
